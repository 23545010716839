import Swiper, { Navigation, Pagination } from "swiper";
const doctorsSlides = document.querySelectorAll(".slide-doctors");
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

Swiper.use([Navigation, Pagination]);

const swiper = new Swiper(".swiper1", {
    // Optional parameters
    loop: true,

    // If we need pagination
    pagination: {
        el: ".swiper-pagination",
    },
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    // Navigation arrows
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

function autoScrollSlider() {
    const sliderContainer = document.querySelector('.slider-container-doctors');
    const sliderWrapper = document.querySelector('.slider-wrapper-doctors');
    const btnPrev = document.querySelector('.prev-doctors');
    const btnNext = document.querySelector('.next-doctors');

    let offset = 0;
    let containerWidth = sliderContainer.offsetWidth;
    let totalWidth = sliderWrapper.scrollWidth;

    function slideNext() {
        offset += containerWidth;
        if (offset >= totalWidth - containerWidth) offset = 0;
        sliderWrapper.style.transform = `translateX(-${offset}px)`;
    }

    function slidePrev() {
        offset -= containerWidth;
        if (offset < 0) offset = totalWidth - containerWidth;
        sliderWrapper.style.transform = `translateX(-${offset}px)`;
    }

    btnNext.addEventListener('click', slideNext);
    btnPrev.addEventListener('click', slidePrev);

    window.addEventListener('resize', () => {
        containerWidth = sliderContainer.offsetWidth;
        totalWidth = sliderWrapper.scrollWidth;
        offset = 0;
        sliderWrapper.style.transform = 'translateX(0)';
    });
}

// Запустить автоматическую прокрутку слайдера при загрузке страницы
window.onload = autoScrollSlider;
doctorsSlides.forEach((slide) => {
    slide.addEventListener("click", () => {
        const id = slide.getAttribute("id");

        document.location.href = `/doctors/${id}`;
    })
});
